
































import { Dictionary } from "@/types/helpers";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class UploadModal extends Vue {
  dialog = false;
  resolve: ((value: File | PromiseLike<File>) => void) | undefined;
  reject: ((value: File | PromiseLike<File>) => void) | undefined;
  title = "";
  file: File | null = null;
  options = {
    color: "",
    width: 400,
    zIndex: 200,
    noconfirm: false,
  };

  open(
    title: string = this.$t("global.dialog.upload.title").toString(),
    options: Dictionary<string | number> = {}
  ): Promise<File> {
    this.dialog = true;
    this.title = title;
    this.options = Object.assign(this.options, options);
    return new Promise<File>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree(): void {
    if (!this.resolve || !this.file) return;
    this.resolve(this.file);
    this.clean();
  }

  cancel(): void {
    if (!this.resolve) return;
    this.clean();
  }

  clean(): void {
    this.file = null;
    this.dialog = false;
  }
}
